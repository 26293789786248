


.down-chevron {
  color: $black-color;
  position: absolute;
  bottom: 20vh;
  font-size: 80px;
}

.home-body {
  margin-top: 150px;
  // height: 500px;

  .home-body-inner {
    border: 1px solid $black-color;
    padding-bottom: 40px;
  }
}

.about-inner {
}

.about-body {
  padding: 0 10%;
}

.about-title {
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 50px;
  color: $sage-color;
}

.map-wrapper {
  text-align: center;
  margin-top: 30px;
  position: relative;
  padding-bottom: 75%; // This is the aspect ratio
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
  }
}

.info-wrapper {
  text-align: center;
  h4 {
    margin: 5px;
  }
}

.hours-wrapper {
  text-align: center;
}
