$header-height: 100px;
$logo-height: 80px;
$sage-color: rgba(178, 186, 165, 1);
$black-color: #000000;

@import "./styles/header";
@import "./styles/home";
@import "./styles/services";
@import "./styles/team";
@import "./styles/footer";


.banner {
  // background: url('./img/SageSalon101.jpg') center center;
  background-color: grey;
  background-size: cover;
  text-align: center;
  margin-top: $header-height;
  width: 100%;
  height: 80vh;
}

.alert-outer {
  border: 2px solid red;
  background-color: rgba(255, 150, 149, 1);
  color: red;
  text-align: center;

  h1 {
    margin: 5px;
  }
}

a {
  text-decoration: none;
  color: rgba(11, 11, 11, 1);
}

a:hover {
  color: rgba(178, 186, 165, 1);
}


@media only screen and (max-width: 600px) {
  .banner {
    margin-top: 0 !important;
  }
}
